<template>
  <div class="truncate">
    <div v-if="!show">
      <span :class="textClass">
        {{ truncate(text) }}
      </span>
      <button v-if="showToggle && text.length >= length" :class="actionClass" @click="toggle">
        {{ clamp }}
      </button>
    </div>
    <div v-else>
      <span>{{ text }}</span>
      <button v-if="showToggle && text.length >= length" :class="actionClass" @click="toggle">
        {{ less }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "VTruncate",
  props: {
    truncated: {
      type: Boolean,
      default: true,
    },
    collapsedTextClass: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      required: true,
    },
    clamp: {
      type: String,
      default: "Read More",
    },
    length: {
      type: Number,
      default: 100,
    },
    less: {
      type: String,
      default: "Show Less",
    },
    actionClass: {
      type: String,
      default: "",
    },
  },
  created() {
    this.show = !this.truncated;
    this.toggle(!this.truncated);
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    isHTML() {
      return this.type === "html";
    },
    textClass() {
      return this.text.length > this.length && this.collapsedTextClass
        ? this.collapsedTextClass
        : "";
    },
    showToggle() {
      return this.text.length > this.length;
    },
  },
  methods: {
    truncate(string) {
      if (string) return string.toString().substring(0, this.length);
      return "";
    },
    toggle(override) {
      const toggled = typeof override === "boolean" ? override : !this.show;
      this.show = toggled;
      this.$emit("toggle", toggled);
    },
  },
  watch: {
    truncated(value) {
      this.toggle(value);
    },
  },
};
</script>