<template>
  <v-list-item class="category-item py-2">
    <div class="category-item__photo">
      <v-img-extend
        v-if="category.photo"
        class="mr-3 rounded-lg"
        :width="isTablet ? 36 : 50"
        :height="isTablet ? 36 : 50"
        :img="category.photo"
        part-path="categories"
        fallback-size="sm"
        size="sm"
      />
    </div>
    <v-list-item-content>
      <v-list-item-title
        class="font-weight-medium text-uppercase"
        :class="isTablet ? 'text-caption' : ''"
      >
        {{ category.title[menuSettings.defaultLanguage] }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-checkbox
        :input-value="isChecked"
        @change="handleChangeCheck(category)"
        :disabled="category.selected"
      />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "VCategoryEditorItem",

  props: ["value", "category"],

  computed: {
    ...mapGetters({
      menuSettings: "menu/menuSettings",
    }),
    isChecked() {
      return (
        this.value.findIndex((category) => category.id === this.category.id) !== -1 ||
        this.category.selected
      );
    },
  },

  methods: {
    handleChangeCheck({ selected, ...category }) {
      if (selected) return;

      if (this.isChecked) {
        this.$emit(
          "input",
          this.value.filter((item) => category.id !== item.id),
        );
      } else {
        this.$emit("input", [...this.value, category]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.move {
  cursor: move;
}

.ghost {
  opacity: 0.3;
  background: #eee;
  color: grey !important;
}

.category-item {
  border-bottom: 1px solid #ddd;
  &:last-of-type {
    border-bottom: none;
  }
  &__photo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__upload-photo {
    position: absolute;
    bottom: -5px;
    right: 5px;
    margin: 0;
    padding: 0;
    background-color: $primary-color;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    &::v-deep .v-input__prepend-outer {
      margin: 0;
      .v-input__icon {
        width: 28px;
        min-width: 28px;
        height: 28px;
      }
      .v-icon {
        color: #fff;
        font-size: 18px;
      }
    }
  }
}

.v-application.theme--dark {
  .category-item {
    border-bottom-color: #555;
  }
}
</style>
