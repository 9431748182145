<template>
  <v-card elevation="2" class="d-flex flex-column">
    <v-toolbar color="accent" dark flat>
      <v-toolbar-title> Default Categories </v-toolbar-title>
      <v-spacer />
      <v-btn @click="handleClickClose" icon>
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-toolbar>
    <v-vertical-scrolling v-if="categories.length" :style="{ height: 'calc(90vh - 116px)' }">
      <v-list class="py-0">
        <v-category-default-item
          v-model="checkedCategories"
          v-for="category in categories"
          :key="category.id"
          :category="category"
        />
      </v-list>
    </v-vertical-scrolling>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="handleClickFinish">Finish</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import VVerticalScrolling from "../../VVerticalScrolling";
import VCategoryDefaultItem from "../items/VCategoryDefaultItem";

export default {
  name: "VCategoryDefaultCard",

  components: {
    VCategoryDefaultItem,
    VVerticalScrolling,
  },

  data() {
    return {
      checkedCategories: [],
    };
  },

  computed: {
    ...mapGetters({
      appSettings: "app/settings",
      menu: "menu/menu",
      defaultCategories: "categories/categories",
    }),
    categories() {
      const { defaultLanguage } = this.appSettings;
      const { categories } = this.menu;

      const titles = categories.map((category) => category.title[defaultLanguage].toUpperCase());

      return this.defaultCategories.map((category) => ({
        ...category,
        selected: titles.includes(category.title[defaultLanguage].toUpperCase()),
      }));
    },
  },

  methods: {
    ...mapActions({ addCategory: "menu/addCategory" }),

    handleClickFinish() {
      this.checkedCategories.forEach((category) => {
        this.addCategory(category);
      });

      this.checkedCategories = [];

      this.$emit("click:close");
    },

    handleClickClose() {
      this.checkedCategories = [];

      this.$emit("click:close");
    },
  },
};
</script>
