<template>
  <div
    class="category-editor"
    :class="{ 'category-editor--create': !editCategory, 'category-editor--edit': editCategory }"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar color="accent" dark flat>
        <v-toolbar-title>
          {{ !editCategory ? $t("Menu.CreateCategory") : $t("Menu.EditCategory") }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn @click="handleCancelCategoryChanges" icon>
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="category-editor__body">
        <v-text-field
          class="mt-0 pt-0 flex-grow-0"
          v-model.trim="form.title[menuSettings.defaultLanguage]"
          :label="$t('Menu.CategoryTitle')"
          counter="80"
          maxlength="80"
          @blur="$v.form.title[menuSettings.defaultLanguage].$touch()"
          :error-messages="titleErrors"
          required
        />

        <v-photo-uploader
          class="category-editor__photo"
          :photo="form.photo"
          size="sm"
          part-path="categories"
          edit-button-size="sm"
          @upload="(photo) => (form.photo = photo)"
          @clear="form.photo = null"
        />
      </v-card-text>
      <v-card-actions class="category-editor__actions">
        <div v-if="!editCategory">
          <span> {{ $t("GlobalUI.Or") }} </span>
          <v-btn
            class="v-btn--white-space"
            color="warning"
            @click="$emit('default-category:show')"
            block
          >
            {{ $t("Menu.SetDefaultCategories") }}
          </v-btn>
        </div>
        <v-btn @click="handleCancelCategoryChanges"> {{ $t("GlobalUI.Cancel") }} </v-btn>
        <v-btn color="primary" @click="handleSaveCategoryChanges">
          {{ $t("GlobalUI.Save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import { mapActions, mapGetters } from "vuex";

import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

import VPhotoUploader from "../../photos/VPhotoUploader";

import menusService from "../../../services/menus.service";

const categorySchema = (languages) => ({
  title: menusService.generateLanguageObject(languages, "string"),
  photo: null,
  visibility: true,
  dishes: [],
});

export default {
  name: "VCategoryEditorCard",

  mixins: [validationMixin],

  components: { VPhotoUploader },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    editCategory: {
      type: Object,
      default: () => null,
    },
  },

  validations() {
    return {
      form: {
        title: {
          [this.menuSettings.defaultLanguage]: { required, maxLength: maxLength(80) },
        },
      },
    };
  },

  data() {
    return {
      form: null,
    };
  },

  watch: {
    isOpen() {
      this.init();
    },
    editCategory() {
      this.init();
    },
  },

  computed: {
    ...mapGetters({ menuSettings: "menu/menuSettings" }),

    /* Validations */
    titleErrors() {
      if (!this.form) return {};

      const language = this.menuSettings.defaultLanguage;
      const errors = [];
      if (!this.$v.form.title[language].$dirty) return errors;
      !this.$v.form.title[language].required && errors.push(this.$t("Validation.FieldRequired"));
      !this.$v.form.title[language].maxLength &&
        errors.push(
          `The title may not be greater than ${this.$v.form.title[language].$params.maxLength.max} characters.`,
        );

      return errors;
    },
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions({
      addCategory: "menu/addCategory",
      updateCategory: "menu/updateCategory",
    }),

    init() {
      const { defaultLanguage, alternativeLanguages } = this.menuSettings;
      const languages = [defaultLanguage, ...alternativeLanguages];

      if (this.isOpen && !this.editCategory) {
        this.form = categorySchema(languages);
      }
      if (this.isOpen && this.editCategory) {
        this.form = {
          ...this.editCategory,
          title: { ...this.editCategory.title },
          photo: this.editCategory.photo ? { ...this.editCategory.photo } : null,
        };
      }
      if (!this.isOpen) {
        this.form = categorySchema(languages);
      }
    },

    handleCancelCategoryChanges() {
      this.$v.$reset();
      this.$emit("category:cancel");
    },
    handleSaveCategoryChanges() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      if (this.editCategory) {
        this.updateCategory({
          categoryId: this.form.id,
          data: this.form,
        });
      } else {
        this.addCategory({
          ...this.form,
          id: uuid(),
        });
      }

      this.$v.$reset();
      this.$emit("category:save");
    },
  },
};
</script>

<style lang="scss" scoped>
.category-editor {
  &--create {
    .category-editor__actions {
      display: grid;
      grid-template-columns: 1fr auto auto;
      gap: 8px;
      > div {
        position: relative;
        span {
          position: absolute;
          top: -50px;
          left: calc(50% - 24px);
          font-size: 28px;
        }
      }
    }
  }
  &--edit {
    .category-editor__actions {
      display: flex;
      justify-content: space-between;
    }
  }
  &__body {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
  }
  &__photo::v-deep.photo-uploader {
    width: 160px;
    margin-right: 8px;
  }
  &__actions {
    margin-top: 20px;
    > .v-btn.v-btn + .v-btn {
      margin: 0;
    }
  }
}

@media screen and (max-width: 576px) {
  .category-editor {
    &--create {
      .category-editor__actions {
        grid-template-columns: 1fr 1fr;
        > div {
          grid-column: 1 / 3;
          span {
            display: block;
            position: static;
            text-align: center;
            padding: 10px 0;
          }
        }
      }
    }
    &__body {
      grid-template-columns: 100%;
    }
    &__photo::v-deep.photo-uploader {
      margin: 0 auto;
    }
  }
}
</style>
