<template>
  <div class="vertical-scrolling">
    <slot />
  </div>
</template>

<script>
export default {
  name: "VVerticalScrolling",
};
</script>

<style lang="scss" scoped>
.vertical-scrolling {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: 100%;
  &::-webkit-scrollbar {
    width: 8px;
    margin: 0 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 3px;
  }
}
</style>
