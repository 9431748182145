<template>
  <div id="menu-constructor" class="menu-constructor">
    <v-menu-toolbar @action="handleClickAction" />
    <div class="menu-constructor-grid mt-5">
      <div class="menu-constructor-col menu-constructor-col--one">
        <v-categories-editor-card />
      </div>
      <div class="menu-constructor-col menu-constructor-col--second">
        <v-menu-editor-card
          :isSettingsEdit="isSettingsEdit"
          @dish:add="handleEditDish"
          @dish:edit="handleEditDish"
        />
      </div>
    </div>

    <v-menu-bottom-toolbar v-if="isTablet" @action="handleClickAction" />

    <v-dialog
      v-model="isDishEdit"
      max-width="768px"
      overlay-color="#2e2e2e"
      overlay-opacity="0.8"
      scrollable
      persistent
    >
      <v-dish-editor-card
        :editDish="editDish"
        :is-open="isDishEdit"
        @save="handleSaveDish"
        @cancel="handleCancelDish"
      />
    </v-dialog>

    <v-dialog
      v-model="isScheduleEdit"
      :width="isScheduleLoading ? '300px' : '768px'"
      overlay-color="#2e2e2e"
      overlay-opacity="0.8"
      persistent
    >
      <v-menu-schedule-editor-card
        v-if="isScheduleEditCard"
        @save="isScheduleEdit = false"
        @cancel="isScheduleEdit = false"
        @loading:start="isScheduleLoading = true"
        @loading:end="isScheduleLoading = false"
      />
    </v-dialog>

    <v-dialog
      v-model="isSettingsEdit"
      max-width="768px"
      overlay-color="#2e2e2e"
      overlay-opacity="0.8"
      persistent
    >
      <v-menu-settings-editor-card
        @save="isSettingsEdit = false"
        @cancel="isSettingsEdit = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import VDishEditorCard from "./cards/VDishEditorCard";
import VMenuEditorCard from "./cards/VMenuEditorCard";
import VCategoriesEditorCard from "./cards/VCategoriesEditorCard";
import VMenuSettingsEditorCard from "./cards/VMenuSettingsEditorCard";
import VMenuScheduleEditorCard from "./cards/VMenuScheduleEditorCard";
import VMenuToolbar from "./VMenuToolbar.vue";
import VMenuBottomToolbar from "./VMenuBottomToolbar.vue";

import { mapGetters } from "vuex";

import { CUSTOMER_URL, API_URL } from "./../../config";
import { confirm } from "../../helpers/dialogs";

import { menuEvents } from "../../constants/events";

export default {
  name: "VMenuManagement",

  components: {
    VDishEditorCard,
    VMenuEditorCard,
    VCategoriesEditorCard,
    VMenuToolbar,
    VMenuBottomToolbar,
    VMenuSettingsEditorCard,
    VMenuScheduleEditorCard,
  },

  data() {
    return {
      CUSTOMER_URL,
      API_URL,

      isDishEdit: false,
      isScheduleEdit: false,
      isScheduleEditCard: false,
      isSettingsEdit: false,

      isScheduleLoading: true,

      editDish: null,

      scheduleTimeout: null,
    };
  },

  computed: {
    ...mapGetters({
      menu: "menu/menu",
      isMenuEqualCache: "menu/isMenuEqualCache",
      isMenuLoading: "menu/isMenuLoading",
      isMenuBusy: "menu/isMenuBusy",
      categoryById: "menu/categoryById",
      selectedCategoryId: "menu/selectedCategoryId",
    }),
    isCreateMenu() {
      return this.$router.history.current.name === "menus.create";
    },
  },

  watch: {
    isScheduleEdit(value) {
      if (value) this.isScheduleEditCard = value;
      if (!value) {
        this.scheduleTimeout = setTimeout(() => {
          this.isScheduleEditCard = value;
        }, 150);
      }
    },
    isMenuLoading(isMenuLoading) {
      if (!isMenuLoading && this.isCreateMenu) {
        this.isSettingsEdit = true;
      }
    },
  },

  created() {
    this.$emitter.on(menuEvents.SHOW_MENU_SETTINGS, () => {
      this.isSettingsEdit = true;
    });

    this.$emitter.on(menuEvents.SHOW_MENU_SCHEDULE, () => {
      this.isScheduleEdit = true;
    });
  },

  destroyed() {
    this.$store.dispatch("menu/clearAll");
    clearTimeout(this.scheduleTimeout);
  },

  methods: {
    async handleClickAction(type) {
      let isConfirm = false;
      switch (type) {
        case "preview":
          this.$emitter.emit(menuEvents.SHOW_MENU_PREVIEW, this.menu.id);
          break;
        case "schedule":
          this.isScheduleEdit = true;
          break;
        case "settings":
          this.isSettingsEdit = true;
          break;
        case "archive-menu":
          isConfirm = await this.$dialog.warning(confirm());
          if (!isConfirm) return;

          await this.$store.dispatch("menu/archiveMenu");
          break;
        case "unarchive-menu":
          isConfirm = await this.$dialog.warning(confirm());
          if (!isConfirm) return;

          await this.$store.dispatch("menu/unArchiveMenu");
          break;
      }
    },
    handleSaveDish({ isEdit, dish }) {
      const editDish = this.editDish;

      switch (true) {
        // Edit & Change CategoryId
        case isEdit && editDish.categoryId !== dish.categoryId:
          this.$store.dispatch("menu/deleteDish", {
            categoryId: editDish.categoryId,
            dishId: dish.id,
          });
          this.$store.dispatch("menu/addDish", {
            categoryId: dish.categoryId,
            dish,
          });
          break;
        // Edit & NOT Change CategoryId
        case isEdit && editDish.categoryId === dish.categoryId:
          // Replace dish in category
          this.$store.dispatch("menu/updateDish", {
            categoryId: dish.categoryId,
            dishId: dish.id,
            dish,
          });
          break;
        // NOT Edit
        case !isEdit:
          // Insert dish in category
          this.$store.dispatch("menu/addDish", {
            categoryId: dish.categoryId,
            dish,
          });
          break;
      }
      if (isEdit) this.editDish = null;

      this.isDishEdit = false;
    },
    handleEditDish(dish = null) {
      this.isDishEdit = true;

      if (dish) {
        this.$nextTick(function () {
          this.editDish = dish;
        });
      }
    },
    handleCancelDish() {
      this.editDish = null;
      this.isDishEdit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-constructor {
  flex-grow: 1;
}

.menu-constructor-grid {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(375px, 1fr) 2fr;
  gap: 10px;
}

@media screen and (max-width: 991px) {
  .menu-constructor {
    margin-bottom: 60px;
  }

  .menu-constructor-grid {
    grid-template-columns: 100%;
  }
}
</style>

