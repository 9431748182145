<template>
  <div class="menu-schedule-editor">
    <!-- Loading -->
    <v-card v-if="isScheduleLoading" color="primary" dark>
      <v-card-text>
        <span class="d-block mb-4">{{ $t("GlobalUI.Loading") }}</span>
        <v-progress-linear indeterminate color="white"></v-progress-linear>
      </v-card-text>
    </v-card>
    <!-- Loaded -->
    <v-card v-if="!isScheduleLoading" elevation="2">
      <v-toolbar color="accent" dark flat>
        <v-toolbar-title> {{ $t("Menu.MenuSchedule") }} </v-toolbar-title>
        <v-spacer />
        <v-btn @click="handleCancelChanges" icon>
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <v-vertical-scrolling :style="{ overflowY: 'scroll', maxHeight: 'calc(90vh - 116px)' }">
        <v-card-text>
          <h3 class="mb-1 text-uppercase">{{ $t("Menu.EnableToOrder") }}</h3>
          <v-switch
            class="v-switch v-switch--two-label v-switch--one-color schedule-switch-status"
            v-model="schedule.isAlwaysShow"
            :true-value="false"
            :false-value="true"
            hide-details
          >
            <template #prepend>
              <span
                class="v-switch__label schedule-switch-status__label"
                :class="{ active: schedule.isAlwaysShow }"
              >
                {{ $t("Menu.Always") }}
              </span>
            </template>
            <template #append>
              <span
                class="v-switch__label schedule-switch-status__label"
                :class="{ active: !schedule.isAlwaysShow }"
              >
                {{ $t("Menu.BySchedule") }}
              </span>
            </template>
          </v-switch>
          <v-expansion-panels
            class="schedule-list"
            :class="{ disabled: schedule.isAlwaysShow }"
            :disabled="schedule.isAlwaysShow"
          >
            <v-expansion-panel class="schedule-item" v-for="day in days" :key="day.day">
              <v-expansion-panel-header>
                <div class="schedule-item__header">
                  <v-icon class="schedule-indicator mr-2" size="15" :color="TYPE_COLOR[day.status]">
                    mdi-circle
                  </v-icon>
                  <span class="schedule-day" v-text="$t(`GlobalUI.${weekDays[day.day]}`)" />
                  <div
                    class="schedule-time-windows"
                    v-if="day.status === 'SPECIFIC' && day.timeWindows.length"
                  >
                    <span
                      v-for="(timeWindow, idx) in day.timeWindows"
                      v-text="`${timeWindow.start} - ${timeWindow.end}`"
                      :key="idx"
                    />
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-radio-group class="mt-0" v-model="day.status">
                  <v-radio :label="$t('Menu.ShowAllDay')" value="SHOW_ALL"></v-radio>
                  <v-radio :label="$t('Menu.HideAllDay')" value="HIDE_ALL"></v-radio>
                  <v-radio :label="$t('Menu.SetSpecificTimes')" value="SPECIFIC"></v-radio>
                </v-radio-group>

                <template v-if="day.status === 'SPECIFIC'">
                  <div class="time-windows">
                    <h3 class="time-windows-title">
                      {{ $t("Menu.TimeWindows") }}
                    </h3>
                    <div class="time-windows-list">
                      <div
                        class="time-windows-item"
                        v-for="(timeWindow, idx) in day.timeWindows"
                        :key="idx"
                      >
                        <v-schedule-time-picker
                          type="start"
                          :label="$t('Menu.StartTime')"
                          :end-time="timeWindow.end"
                          v-model="timeWindow.start"
                          :time-format="appSettings.timeFormat"
                        />
                        <v-schedule-time-picker
                          type="end"
                          :label="$t('Menu.EndTime')"
                          :start-time="timeWindow.start"
                          v-model="timeWindow.end"
                          :time-format="appSettings.timeFormat"
                        />
                        <v-btn @click="handleClickDeleteTimeWindow(day, idx)" icon>
                          <v-icon color="error"> mdi-close-circle-outline </v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <div class="time-windows-add">
                      <a
                        class="text-decoration-none"
                        href="javascript:void(0)"
                        @click="handleClickAddTimeWindow(day)"
                      >
                        + {{ $t("Menu.AddNewTimeSlot") }}
                      </a>
                    </div>
                  </div>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-vertical-scrolling>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="handleCancelChanges">
          {{ $t("GlobalUI.Cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="handleSaveChanges"
          :loading="isScheduleBusy"
          :disabled="isScheduleLoading"
        >
          {{ $t("GlobalUI.Save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import VVerticalScrolling from "../../VVerticalScrolling";
import VScheduleTimePicker from "../../VTimePicker";

import scheduleService from "../../../services/schedule.service";
import { mergeTimeWindows } from "../../../services/validators.service";

const TYPE_COLOR = {
  SHOW_ALL: "green",
  HIDE_ALL: "orange",
  SPECIFIC: "blue",
};

export default {
  name: "VMenuScheduleEditorCard",

  components: {
    VVerticalScrolling,
    VScheduleTimePicker,
  },

  data() {
    return {
      TYPE_COLOR,

      isScheduleLoading: true,
      isScheduleBusy: false,

      schedule: {
        menuId: null,
        isAlwaysShow: true,
        days: [
          {
            day: 0,
            dayCode: "sun",
            status: "SHOW_ALL",
            timeWindows: [],
          },
          {
            day: 1,
            dayCode: "mon",
            status: "SHOW_ALL",
            timeWindows: [],
          },
          {
            day: 2,
            dayCode: "tue",
            status: "SHOW_ALL",
            timeWindows: [],
          },
          {
            day: 3,
            dayCode: "wed",
            status: "SHOW_ALL",
            timeWindows: [],
          },
          {
            day: 4,
            dayCode: "thu",
            status: "SHOW_ALL",
            timeWindows: [],
          },
          {
            day: 5,
            dayCode: "fri",
            status: "SHOW_ALL",
            timeWindows: [],
          },
          {
            day: 6,
            dayCode: "sat",
            status: "SHOW_ALL",
            timeWindows: [],
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      menu: "menu/menu",
      appSettings: "app/settings",
    }),
    days() {
      const firstDayIndex = this.schedule.days.findIndex(
        ({ dayCode }) => dayCode === this.appSettings.firstDayOfWeek,
      );
      return this.schedule.days
        .slice(firstDayIndex)
        .concat(this.schedule.days.slice(0, firstDayIndex));
    },
    weekDays() {
      return moment.weekdays();
    },
  },

  async created() {
    this.$emit("loading:start");
    const schedule = await scheduleService.getByMenuId(this.menu.id);

    if (schedule) {
      this.schedule = schedule;
    }

    this.isScheduleLoading = false;
    this.$emit("loading:end");
  },

  methods: {
    handleDiscardChanges() {
      this.$emit("discard");
    },

    handleCancelChanges() {
      this.$emit("cancel");
    },

    handleClickAddTimeWindow(day) {
      day.timeWindows.push({ start: "00:00", end: "00:15" });
    },

    handleClickDeleteTimeWindow(day, idx) {
      day.timeWindows.splice(idx, 1);
    },

    async handleSaveChanges() {
      const scheduleDays = this.schedule.days
        // If timeWindows is empty, then replace the status with "SHOW_ALL"
        .map(scheduleService.replaceStatusIfTimeWindowsEmpty)
        // If timeWindow equal [00:00 - 00:00], then replace the status with "SHOW_ALL"
        .map(scheduleService.replaceStatusIfTimeWindowsFull)
        // If status equal 'SHOW_ALL' or 'HIDE_ALL', then delete all timeWindows
        .map(scheduleService.deleteUnnecessaryTimeWindows)
        // Merge Time Overlapping
        .map(mergeTimeWindows);

      const schedule = {
        ...this.schedule,
        menuId: this.menu.id,
        days: scheduleDays,
      };

      try {
        this.isScheduleBusy = true;
        await scheduleService.save(schedule);
        // this.schedule = schedule;
        this.$emit("save", schedule);
      } catch (error) {
        console.error(error);
      } finally {
        this.isScheduleBusy = false;
      }
    },
  },
};
</script>

<style lang="scss">
.schedule-switch-status {
  grid-template-columns: repeat(3, auto);
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

.schedule-list {
  &.disabled {
    .schedule-indicator {
      opacity: 0.38;
    }
  }
}

.schedule-item {
  &__header {
    display: flex;
    align-items: center;
  }
}

.schedule-day {
  white-space: nowrap;
  font-size: 15px;
  margin-right: auto;
}

.schedule-time-windows {
  max-width: 280px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 5px 10px;
  font-size: 15px;
  margin-left: 5px;
  margin-right: 20px;
  span {
    display: block;
    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }
}

.time-windows-title {
  display: block;
  margin-bottom: 15px;
}

.time-windows-list {
  display: grid;
  gap: 10px 15px;
}

.time-windows-item {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 15px;
}

.time-windows-add {
  margin-top: 15px;
}

@media screen and (max-width: 576px) {
  .schedule-item {
    &__header {
      flex-wrap: wrap;
    }
  }

  .schedule-time-windows {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
    justify-content: flex-start;
  }
}
</style>