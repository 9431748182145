import { render, staticRenderFns } from "./MenuCreate.vue?vue&type=template&id=7689b0f8&scoped=true&"
import script from "./MenuCreate.vue?vue&type=script&lang=js&"
export * from "./MenuCreate.vue?vue&type=script&lang=js&"
import style0 from "./MenuCreate.vue?vue&type=style&index=0&id=7689b0f8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7689b0f8",
  null
  
)

export default component.exports