<template>
  <div
    class="category-item"
    :class="{
      unpublished: !category.visibility,
      active: selectedCategoryId === localCategory.id,
    }"
    @click="handleSelectCategory"
  >
    <div class="category-item__photo">
      <v-img-extend
        class="rounded-lg"
        :width="isTablet ? 50 : 60"
        :height="isTablet ? 50 : 60"
        :img="localCategory.photo"
        part-path="categories"
        fallback-size="sm"
        size="sm"
      />
    </div>
    <div class="category-item__content">
      <div class="category-item__title">
        {{ localCategory.title[menuSettings.defaultLanguage] }}
      </div>
      <div class="category-item__meta">
        {{ localCategory.dishes.length }} {{ $t("Menu.Items").toLowerCase() }}
      </div>
    </div>
    <div class="category-item__actions">
      <v-menu offset-y min-width="140">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon size="32">mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list-extend :x-dense="!isTablet" nav>
          <v-list-item @click.prevent="$emit('category:edit', localCategory)" link>
            <v-list-item-icon class="mr-1">
              <v-icon size="18">mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("GlobalUI.Edit") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.prevent="$emit('category:toggle-visibility', localCategory)" link>
            <v-list-item-icon class="mr-1">
              <v-icon v-if="localCategory.visibility" size="18">mdi-eye-off</v-icon>
              <v-icon v-else color="grey" size="18">mdi-eye</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                localCategory.visibility ? $t("Menu.Unpublish") : $t("Menu.Publish")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.prevent="$emit('category:delete', localCategory)" link>
            <v-list-item-icon class="mr-1">
              <v-icon size="18">mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("GlobalUI.Delete") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-extend>
      </v-menu>
      <v-btn class="category-item__move move" icon>
        <v-icon>mdi-unfold-more-horizontal</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "VCategoryItem",

  props: ["category"],

  data() {
    return {
      localCategory: { ...this.category },
    };
  },

  watch: {
    category(category) {
      this.localCategory = category;
    },
  },

  computed: {
    ...mapGetters({
      menuSettings: "menu/menuSettings",
      selectedCategoryId: "menu/selectedCategoryId",
    }),
  },

  methods: {
    handleSelectCategory() {
      this.$emit("category:select", this.localCategory);
    },

    handleToggleVisibilityCategory() {
      this.$emit("category:toggle-visibility", this.localCategory);
    },

    handleEditCategory() {
      this.$emit("category:edit", this.localCategory);
    },

    handleDeleteCategory() {
      this.$emit("category:delete", this.localCategory);
    },
  },
};
</script>

<style lang="scss" scoped>
.move {
  cursor: move;
}

.ghost,
.unpublished {
  opacity: 0.3;
  background: #eee;
  color: grey !important;
}

.category-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 16px;
  padding-right: 0;
  cursor: pointer;
  &.active {
    background-color: $secondary-color;
  }
  &:last-of-type {
    border-bottom: none;
  }
  &__photo {
    margin-right: 12px;
  }
  &__title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  &__meta {
    font-size: 0.875rem;
  }
  &__move {
    margin-right: 5px;
  }
}

.v-application.theme--dark {
  .category-item {
    border-bottom-color: #555;
    &.active {
      background-color: darken($secondary-color, 70%);
    }
  }
}
</style>
