export type QueryParams = { [key: string]: string | number };

export const getQueryParams = (params: QueryParams) => {
  const queryParamsArray: string[] = [];
  Object.keys(params).forEach((key) => {
    queryParamsArray.push(`${key}=${params[key]}`);
  });

  const queryParams = queryParamsArray.join("&");

  return queryParams ? `?${queryParams}` : "";
};
