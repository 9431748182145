import axios from "axios";

import catchErrors from "@/helpers/catchErrors";

import { TagModel } from "@/@types/model";
import { API_URL } from "../config";

const getTags = catchErrors<TagModel[]>(async () => {
  const { data, status } = await axios.get<TagModel[]>(`${API_URL}/tags`);

  return status === 200 && data ? data : [];
});

export default { getTags };
