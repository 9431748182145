<template>
  <v-bottom-navigation class="menu-bottom-toolbar" grow>
    <v-btn
      @click="handleDiscardMenu"
      :loading="isMenuBusy"
      :disabled="isMenuBusy || isMenuLoading || isMenuEqualCache"
    >
      <span class="mt-1">{{ $t("GlobalUI.Discard") }}</span>
      <v-icon> mdi-restore </v-icon>
    </v-btn>
    <v-btn
      @click="handleSaveMenu($event, true)"
      :loading="isMenuBusy"
      :disabled="isMenuBusy || isMenuLoading || isMenuEqualCache"
    >
      <span class="mt-1" :class="!isMenuEqualCache ? 'orange--text' : undefined">
        {{ $t("GlobalUI.Save") }}
      </span>
      <v-icon :color="!isMenuEqualCache ? 'orange' : undefined"> mdi-content-save </v-icon>
    </v-btn>
    <v-btn
      @click="handleClickAction('preview')"
      :disabled="!menu.id || isMenuBusy || isMenuLoading"
    >
      <span class="mt-1">Preview</span>
      <v-icon> mdi-cellphone </v-icon>
    </v-btn>
    <v-btn @click="handleClickAction('settings')">
      <span class="mt-1">Settings</span>
      <v-icon> mdi-cog </v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "VMenuBottomToolbar",

  computed: {
    ...mapGetters({
      menu: "menu/menu",
      isMenuEqualCache: "menu/isMenuEqualCache",
      isMenuLoading: "menu/isMenuLoading",
      isMenuBusy: "menu/isMenuBusy",
    }),
  },

  methods: {
    ...mapActions({
      saveMenu: "menu/saveMenu",
      discardMenu: "menu/discardMenu",
    }),

    async handleSaveMenu() {
      await this.saveMenu();
    },

    handleDiscardMenu() {
      this.discardMenu();
    },

    handleClickAction(type) {
      this.$emit("action", type);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-bottom-toolbar {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>