<template>
  <v-list class="ingredient-list">
    <draggable
      v-model="localIngredients"
      handle=".move"
      group="menu"
      v-bind="dragOptions"
      @start="dragIngredients = true"
      @end="dragIngredients = false"
      @change="$emit('change', localIngredients)"
    >
      <transition-group type="transition" tag="div" :name="!dragIngredients ? 'flip-list' : null">
        <v-list-item
          class="ingredient-item"
          v-for="ingredient in localIngredients"
          :key="ingredient[menuSettings.defaultLanguage]"
        >
          <v-list-item-icon class="ingredient-item__icon move my-2 mr-2">
            <v-btn icon>
              <v-icon color="grey">mdi-unfold-more-horizontal</v-icon>
            </v-btn>
          </v-list-item-icon>
          <v-list-item-content class="ingredient-item__content">
            <v-list-item-title class="ingredient-item__title">
              {{ ingredient[menuSettings.defaultLanguage] }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </transition-group>
    </draggable>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "VIngredientsSorter",

  components: {
    draggable,
  },

  props: ["ingredients"],

  data() {
    return {
      localIngredients: this.ingredients,

      dragIngredients: false,
    };
  },

  computed: {
    ...mapGetters({ menuSettings: "menu/menuSettings" }),

    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>


<style lang="scss" scoped>
.move {
  cursor: move;
}

.ghost {
  opacity: 0.3;
  background: #eee;
  color: grey !important;
}

.ingredient-list {
  max-height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    margin: 0 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 10px;
  }
}

.ingredient-item {
  display: flex;
  align-items: center;
  &__title {
    text-transform: uppercase;
  }
}
</style>