<template>
  <div class="menu-card">
    <v-card elevation="2">
      <v-toolbar flat>
        <v-toolbar-title>
          {{ !isShowAllDishes ? $t("Menu.Items") : $t("Menu.AllItems") }}
        </v-toolbar-title>
        <v-btn
          v-if="!isShowAllDishes && allDishes.length"
          class="ml-5"
          @click="handleClickShowAll"
          small
        >
          {{ $t("GlobalUI.ShowAll") }}
        </v-btn>

        <v-spacer></v-spacer>

        <v-add-btn
          :action="() => $emit('dish:add')"
          size="42px"
          :disabled="!categoriesVisible.length"
        />
      </v-toolbar>

      <v-divider></v-divider>

      <div
        class="menu-card__content"
        :class="{
          'menu-card__content--loading': isMenuLoading,
          'menu-card__content--empty': !isMenuLoading && !dishes.length,
        }"
      >
        <v-vertical-scrolling
          v-if="(selectedCategoryId && dishes.length) || (isShowAllDishes && dishes.length)"
        >
          <div class="dish-list">
            <draggable
              v-model="dishes"
              handle=".move"
              group="dishes"
              v-bind="dragOptions"
              :disabled="isShowAllDishes"
              @start="dragDish = true"
              @end="dragDish = false"
            >
              <v-dish-item
                v-for="dish in dishes"
                :key="dish.id"
                :dish="dish"
                :currency="menu.priceCurrency"
                :is-draggable-disabled="isShowAllDishes"
                @dish:duplicate="handleDuplicateDish"
                @dish:edit="handleEditDish"
                @dish:delete="handleDeleteDish"
              />
            </draggable>
          </div>
        </v-vertical-scrolling>
        <v-progress-circular
          v-if="isMenuLoading"
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <div v-if="!isMenuLoading && !dishes.length">
          {{ $t("Messages.Empty") }}
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";

import VVerticalScrolling from "../../VVerticalScrolling";

import VDishItem from "../items/VDishItem";

export default {
  name: "VMenuEditorCard",

  components: {
    draggable,
    VVerticalScrolling,
    VDishItem,
  },

  data() {
    return {
      isShowAllDishes: false,
      dragDish: false,
    };
  },

  computed: {
    ...mapGetters({
      menu: "menu/menu",
      isMenuLoading: "menu/isMenuLoading",
      allDishes: "menu/allDishes",
      categoryById: "menu/categoryById",
      categoriesVisible: "menu/categoriesVisible",
      selectedCategoryId: "menu/selectedCategoryId",
    }),
    dishes: {
      get() {
        if (!this.selectedCategory) {
          if (this.isShowAllDishes) return this.allDishes;
          else return [];
        }
        return this.selectedCategory.dishes;
      },
      set(dishes) {
        if (!this.selectedCategory) return;
        this.setDishes({
          categoryId: this.selectedCategoryId,
          dishes,
        });
      },
    },
    selectedCategory() {
      return this.categoryById(this.selectedCategoryId);
    },
    dragOptions() {
      return {
        animation: 200,
        ghostClass: "ghost",
      };
    },
  },

  watch: {
    selectedCategory(newCategory) {
      if (newCategory) {
        this.isShowAllDishes = false;
      }
    },
  },

  methods: {
    ...mapActions({
      setSelectedCategoryId: "menu/setSelectedCategoryId",
      setDishes: "menu/setDishes",
      addDish: "menu/addDish",
      deleteDish: "menu/deleteDish",
    }),

    handleClickShowAll() {
      this.setSelectedCategoryId(null);
      this.isShowAllDishes = true;
    },

    async handleDuplicateDish(dish) {
      this.addDish({
        categoryId: dish.categoryId,
        dish,
      });
    },

    handleEditDish(dish) {
      this.$emit("dish:edit", dish);
    },

    handleDeleteDish({ id: dishId, categoryId }) {
      this.deleteDish({ categoryId, dishId });
    },
  },
};
</script>

<style lang="scss" scoped>
.move {
  cursor: move;
}

.ghost {
  opacity: 0.3;
  background: #eee;
  color: grey !important;
}

.menu-card {
  width: 100%;
  &__content {
    width: 100%;
    height: calc(100vh - 240px);
    &--loading,
    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.dish-list {
  padding-top: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 991px) {
  .menu-card {
    &__content {
      height: auto;
      min-height: 120px;
    }
  }
}
</style>
