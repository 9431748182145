<template>
  <div class="dish-card">
    <v-card>
      <v-vertical-scrolling :style="{ height: 'calc(90vh - 52px)' }">
        <v-card-text>
          <div class="d-flex flex-column flex-sm-row mb-5">
            <div class="d-flex flex-column flex-grow-1">
              <v-dish-availability-block
                class="mb-5"
                :is-published="dish.isPublished"
                :is-enabled-to-order="dish.isEnabledToOrder"
                @change:published="handleChangePublishedDish"
                @change:enabled-order="handleChangeEnabledOrderDish"
              />

              <v-textarea
                v-model.trim="dish.title[menuSettings.defaultLanguage]"
                :label="`${$t('Menu.ItemTitle')} *`"
                @blur="$v.dish.title[menuSettings.defaultLanguage].$touch()"
                :error-messages="titleErrors"
                rows="1"
                auto-grow
                dense
              />

              <div class="internal-and-categories-block">
                <v-text-field
                  v-model.trim="dish.internalId"
                  :label="$t('Menu.ItemID')"
                  counter="8"
                  maxlength="8"
                >
                  <v-tooltip slot="append" max-width="300" right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
                    </template>
                    <span>{{ $t("Menu.ItemIDComment") }}</span>
                  </v-tooltip>
                </v-text-field>

                <v-select
                  v-model="dish.categoryId"
                  :items="categoriesVisible"
                  :item-text="`title.${menuSettings.defaultLanguage}`"
                  item-value="id"
                  :label="`${$t('Menu.Category')} *`"
                  @blur="$v.dish.categoryId.$touch()"
                  :error-messages="categoryIdErrors"
                />
              </div>
            </div>

            <v-photo-uploader
              class="dish-card__upload-photo"
              :photo="dish.photo"
              size="sm"
              edit-button-size="sm"
              @upload="(photo) => (dish.photo = photo)"
              @clear="dish.photo = null"
            />
          </div>

          <v-textarea
            class="mb-3"
            v-model.trim="dish.description[menuSettings.defaultLanguage]"
            :label="$t('Menu.Description')"
            rows="1"
            hide-details
            auto-grow
            dense
          />

          <v-combobox
            v-model="dish.ingredients"
            :items="ingredientsList"
            :search-input.sync="searchIngredients"
            @change="handleChangeIngredients"
            :label="$t('Menu.Ingredients')"
            :loading="isIngredientsLoading"
            :menu-props="{ closeOnClick: true }"
            :hint="$t('Menu.ChooseFromPreInstalledOrAddNew')"
            :item-text="menuSettings.defaultLanguage"
            persistent-hint
            multiple
          >
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                :key="item[menuSettings.defaultLanguage]"
                v-bind="attrs"
                :input-value="selected"
                @click:close="parent.selectItem(item)"
                close
                label
                small
              >
                {{ item[menuSettings.defaultLanguage] }}
              </v-chip>
            </template>
            <template v-slot:append-outer>
              <v-btn
                v-if="dish.ingredients.length > 1"
                @click="isShowIngredientsDialog = true"
                small
              >
                {{ $t("Menu.Sort") }}
              </v-btn>
            </template>
          </v-combobox>

          <div
            class="dish-grid"
            :class="{ 'dish-grid--one-vat-rate': menuSettings.vatRates.length === 1 }"
          >
            <div class="allergens-block">
              <v-select
                v-model="dish.allergens"
                :items="allergensList"
                :menu-props="{ offsetY: true, closeOnClick: true }"
                item-text="number"
                :label="$t('Menu.Allergens')"
                :hint="$t('Menu.ChooseFromPreInstalled')"
                persistent-hint
                return-object
                multiple
              />
            </div>
            <div v-if="menuSettings.vatRates.length > 1" class="dine-in-rate-block">
              <v-select
                v-model="dish.vatRates.dineInRate"
                :items="menuSettings.vatRates"
                item-text="value"
                item-value="id"
                :label="$t('Menu.VatRateDineIn')"
              />
            </div>
            <div v-if="menuSettings.vatRates.length === 1" class="vat-rate-block">
              <v-select
                :value="dish.vatRates.dineInRate"
                :items="menuSettings.vatRates"
                item-text="value"
                item-value="id"
                label="VAT Rate"
                readonly
              >
                <v-tooltip slot="append-outer" max-width="300" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
                  </template>
                  <span>{{ $t("Settings.VatSingleRateHelperDisheditor") }}</span>
                </v-tooltip>
              </v-select>
            </div>
            <div class="tags-block">
              <v-combobox
                v-model="dish.tags"
                :items="tagsList"
                @change="handleChangeTags"
                :menu-props="{ offsetY: true, closeOnClick: true }"
                :label="$t('Menu.Tags')"
                :loading="isTagsLoading"
                :item-text="`text.${menuSettings.defaultLanguage}`"
                :hint="$t('Menu.ChooseFromPreInstalledOrAddNew')"
                persistent-hint
                return-object
                multiple
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    :key="item.icon"
                    v-bind="attrs"
                    :input-value="selected"
                    @click:close="parent.selectItem(item)"
                    close
                    label
                    small
                  >
                    {{ item.text[menuSettings.defaultLanguage] }}
                  </v-chip>
                </template>
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-action>
                      <v-checkbox :input-value="attrs.inputValue"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters align="center">
                          <span>{{ item.text[menuSettings.defaultLanguage] }}</span>
                          <v-spacer></v-spacer>
                          <span>{{ item.icon }}</span>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </div>
            <div v-if="menuSettings.vatRates.length > 1" class="take-away-rate-block">
              <v-select
                v-model="dish.vatRates.takeAwayRate"
                :items="menuSettings.vatRates"
                item-text="value"
                item-value="id"
                :label="$t('Menu.VatRateTakeAway')"
              />
            </div>
          </div>

          <div class="dish-main-option">
            <div
              v-if="dish.optionsType === 'DIFFERENT' && dish.options.length !== 1"
              class="dish-main-option__disabled"
            >
              {{ $t("Menu.EditParameteresInsideOptions") }}
            </div>
            <v-text-field
              v-model.trim="dish.options[0].amount[menuSettings.defaultLanguage]"
              class="dish-main-option__control dish-main-option__control--amount"
              :label="$t('Menu.Amount')"
              :disabled="dish.optionsType === 'DIFFERENT' && dish.options.length !== 1"
            />
            <div class="dish-main-option__energy-and-price">
              <v-text-field
                class="dish-main-option__control dish-main-option__control--energy"
                :class="{ 'dish-main-option__control--energy--off': !menu.isEnergyVisible }"
                v-model.trim="dish.options[0].energy"
                :label="$t('Menu.Energy')"
                :suffix="`${$t('Menu.Cal')}.`"
                @blur="$v.dish.options.$each[0].energy.$touch()"
                :error-messages="numericErrors(0, 'energy')"
                :disabled="dish.optionsType === 'DIFFERENT' && dish.options.length !== 1"
              />
              <v-text-field
                class="dish-main-option__control dish-main-option__control--price"
                v-model="dish.options[0].priceValue"
                :label="$t('Menu.Price')"
                :suffix="menuSettings.defaultCurrency"
                :disabled="dish.optionsType === 'DIFFERENT' && dish.options.length !== 1"
                @blur="$v.dish.options.$each[0].priceValue.$touch()"
                :error-messages="numericErrors(0, 'priceValue')"
              />
            </div>
          </div>

          <v-dish-options-editor
            :type="dish.optionsType"
            :options="dish.options"
            :default-option="dish.defaultOption"
            @change:type="handleOptionsTypeChange"
            @change:options="handleOptionsChange"
            @change:default-option="handleChangeDefaultOption"
          />
          <v-dish-extras-editor :extras="dish.extras" @change="handleExtrasChange" />
        </v-card-text>
      </v-vertical-scrolling>
      <v-card-actions>
        <v-btn @click="cancel">{{ $t("GlobalUI.Cancel") }}</v-btn>
        <v-spacer />
        <v-btn color="primary" @click="save">{{ $t("GlobalUI.Save") }}</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="isShowIngredientsDialog"
      max-width="400px"
      overlay-color="#2e2e2e"
      overlay-opacity="0.8"
      scrollable
    >
      <v-card>
        <v-card-title>{{ $t("Menu.IngredientsSorter") }}</v-card-title>
        <v-divider></v-divider>
        <v-ingredients-sorter
          :ingredients="dish.ingredients"
          @change="(value) => (this.dish.ingredients = value)"
        />
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn text @click="isShowIngredientsDialog = false">{{ $t("GlobalUI.Close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import { mapGetters } from "vuex";

import { validationMixin } from "vuelidate";
import { required, decimal, minValue } from "vuelidate/lib/validators";

import VVerticalScrolling from "../../VVerticalScrolling";
import VPhotoUploader from "../../photos/VPhotoUploader";

import VIngredientsSorter from "../VIngredientsSorter";
import VDishOptionsEditor, { optionSchema } from "../VDishOptionsEditor";
import VDishExtrasEditor from "../VDishExtrasEditor";
import VDishAvailabilityBlock from "../VDishAvailabilityBlock.vue";

import ingredientsService from "../../../services/ingredients.service";
import allergensService from "../../../services/allergens.service";
import tagsService from "../../../services/tags.service";
import menusService from "../../../services/menus.service";

const dishSchema = (languages) => ({
  id: null,
  internalId: "",
  vatRates: {
    dineInRate: null,
    takeAwayRate: null,
  },
  photo: null,
  isPublished: true,
  isEnabledToOrder: true,
  title: menusService.generateLanguageObject(languages, "string"),
  description: menusService.generateLanguageObject(languages, "string"),
  ingredients: [],
  allergens: [],
  tags: [],
  optionsType: "SAME",
  options: [optionSchema(languages)],
  defaultOption: null,
  extras: [],
  categoryId: null,
});

export default {
  name: "VDishEditorCard",

  mixins: [validationMixin],

  components: {
    VIngredientsSorter,
    VPhotoUploader,

    VVerticalScrolling,
    VDishOptionsEditor,
    VDishExtrasEditor,
    VDishAvailabilityBlock,
  },

  provide() {
    return {
      $v: this.$v,
    };
  },

  validations() {
    let isManyOptions = 1;
    if (this.dish) {
      isManyOptions = this.dish.options.length > 1;
    }
    return {
      dish: {
        title: {
          [this.menuSettings.defaultLanguage]: { required },
        },
        categoryId: { required },
        options: {
          $each: {
            title: isManyOptions ? { [this.menuSettings.defaultLanguage]: { required } } : {},
            energy: { decimal, minValue: minValue(0) },
            priceValue: { decimal, minValue: minValue(0) },
          },
        },
        extras: {
          $each: {
            title: { [this.menuSettings.defaultLanguage]: { required } },
            energy: { decimal, minValue: minValue(0) },
            priceValue: { decimal, minValue: minValue(0) },
          },
        },
      },
    };
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    editDish: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      ingredientsList: [],
      allergensList: [],
      tagsList: [],

      dish: null,

      searchIngredients: null,
      isIngredientsLoading: false,

      isAllergensLoading: false,

      searchTags: null,
      isTagsLoading: false,

      isShowIngredientsDialog: false,
    };
  },

  computed: {
    ...mapGetters({
      menu: "menu/menu",
      menuSettings: "menu/menuSettings",
      categoriesVisible: "menu/categoriesVisible",
      selectedCategoryId: "menu/selectedCategoryId",
    }),

    isEdit() {
      return !!this.editDish;
    },

    /* Validations */
    titleErrors() {
      const errors = [];
      if (!this.$v.dish.title.$dirty) return errors;
      !this.$v.dish.title[this.menuSettings.defaultLanguage].required &&
        errors.push(this.$t("Validation.FieldRequired"));
      return errors;
    },

    numericErrors() {
      return (idx, field) => {
        const errors = [];
        if (!this.$v.dish.options.$each[idx][field].$dirty) return errors;
        !this.$v.dish.options.$each[idx][field].decimal &&
          errors.push("The field must be a numeric.");
        !this.$v.dish.options.$each[idx][field].minValue &&
          errors.push("The field must be at least 0.");
        return errors;
      };
    },

    categoryIdErrors() {
      const errors = [];
      if (!this.$v.dish.categoryId.$dirty) return errors;
      !this.$v.dish.categoryId.required && errors.push(this.$t("Validation.FieldRequired"));
      return errors;
    },
  },

  watch: {
    isOpen() {
      this.init();
    },

    editDish() {
      this.init();
    },

    selectedCategoryId(value) {
      this.dish.categoryId = value;
    },

    async searchIngredients(searchText) {
      if (this.isIngredientsLoading) return;
      if (!searchText || searchText.length < 3) return;

      const { defaultLanguage, alternativeLanguages } = this.menuSettings;
      const languages = [defaultLanguage, ...alternativeLanguages];

      this.isIngredientsLoading = true;
      try {
        const ingredients = await ingredientsService.getIngredients({
          filters: { lang: defaultLanguage, s: searchText },
        });

        this.ingredientsList = ingredients.map((ingredient) =>
          menusService.mergeLanguageObjects(languages, ingredient, "string"),
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.isIngredientsLoading = false;
      }
    },
  },

  created() {
    this.init();

    this.fetchAllergens();
    this.fetchAllTags();
  },

  methods: {
    init() {
      const { defaultLanguage, alternativeLanguages } = this.menuSettings;
      const languages = [defaultLanguage, ...alternativeLanguages];

      if (this.isOpen && !this.editDish) {
        this.dish = {
          ...dishSchema(languages),
          categoryId: this.selectedCategoryId,
        };
      }
      if (this.isOpen && this.editDish) {
        this.dish = {
          ...this.dish,
          ...this.editDish,
          title: { ...this.editDish.title },
          description: { ...this.editDish.description },
          vatRates: { ...this.dish.vatRates, ...this.editDish.vatRates },
          photo: this.editDish.photo ? { ...this.editDish.photo } : null,
          // Deep copy
          options: this.editDish.options.map((option) => ({ ...option })),
          // Deep copy
          extras: this.editDish.extras.map((extra) => ({ ...extra })),
          defaultOption: this.editDish.defaultOption || null,
        };
      }
      if (!this.isOpen) {
        this.dish = dishSchema(languages);
      }

      if (this.menuSettings.vatRates.length === 1) {
        this.dish.vatRates.dineInRate = this.menuSettings.vatRates[0].id;
        this.dish.vatRates.takeAwayRate = this.menuSettings.vatRates[0].id;
      }
    },

    async fetchAllergens() {
      this.isAllergensLoading = true;

      const { defaultLanguage, alternativeLanguages } = this.menuSettings;
      const languages = [defaultLanguage, ...alternativeLanguages];

      try {
        const allergens = await allergensService.getAllergens();

        this.allergensList = allergens.map((allergen) => ({
          number: allergen.number,
          text: menusService.mergeLanguageObjects(languages, allergen.text, "string"),
        }));
      } catch (e) {
        console.error(e);
      } finally {
        this.isAllergensLoading = false;
      }
    },

    async fetchAllTags() {
      this.isTagsLoading = true;

      const { defaultLanguage, alternativeLanguages } = this.menuSettings;
      const languages = [defaultLanguage, ...alternativeLanguages];

      try {
        const tags = await tagsService.getTags();

        this.tagsList = tags
          .map((tag) => ({
            icon: tag.icon,
            text: menusService.mergeLanguageObjects(languages, tag.text, "string"),
          }))
          .filter((tag) => Boolean(tag.text[defaultLanguage]));
      } catch (e) {
        console.error(e);
      } finally {
        this.isTagsLoading = false;
      }
    },

    handleChangeIngredients() {
      const { defaultLanguage, alternativeLanguages } = this.menuSettings;
      const languages = [defaultLanguage, ...alternativeLanguages];

      this.searchIngredients = "";
      this.dish.ingredients = this.dish.ingredients.map((ingredient) => {
        if (typeof ingredient === "string") {
          return {
            ...menusService.generateLanguageObject(languages, "string"),
            [defaultLanguage]: ingredient,
          };
        }
        return ingredient;
      });
    },

    handleChangeTags() {
      const { defaultLanguage, alternativeLanguages } = this.menuSettings;
      const languages = [defaultLanguage, ...alternativeLanguages];

      this.dish.tags = this.dish.tags.map((tag) => {
        if (typeof tag === "string") {
          return {
            icon: null,
            text: {
              ...menusService.generateLanguageObject(languages, "string"),
              [defaultLanguage]: tag,
            },
          };
        }
        return tag;
      });
    },

    handleChangePublishedDish({ isPublished, isEnabledToOrder }) {
      this.dish.isPublished = isPublished;
      this.dish.isEnabledToOrder = isEnabledToOrder;
    },

    handleChangeEnabledOrderDish({ isEnabledToOrder }) {
      this.dish.isEnabledToOrder = isEnabledToOrder;
    },

    handleOptionsTypeChange(optionsType) {
      this.dish.optionsType = optionsType;
    },

    handleOptionsChange(options) {
      if (options.length === 1) {
        this.$v.dish.options.$reset();
      }
      this.dish.options = options;
    },

    handleChangeDefaultOption(value) {
      this.dish.defaultOption = value;
    },

    handleExtrasChange(extras) {
      this.dish.extras = extras;
    },

    save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      let options = [...this.dish.options];
      if (this.dish.optionsType === "SAME") {
        const firstOption = options.shift();
        options = options.map((option) => ({
          ...option,
          amount: firstOption.amount,
          energy: firstOption.energy,
          priceValue: firstOption.priceValue,
        }));
        options = [firstOption, ...options];
      }

      this.$emit("save", {
        isEdit: this.isEdit,
        dish: {
          ...this.dish,
          id: this.dish.id ? this.dish.id : uuid(),
          options: options.map((option) => ({
            ...option,
            priceValue: option.priceValue || null,
          })),
          extras: this.dish.extras.map((extra) => ({
            ...extra,
            priceValue: extra.priceValue || null,
          })),
        },
      });

      this.$v.$reset();
    },

    cancel() {
      this.$emit("cancel");
      this.$v.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./VDishEditorCard";
</style>
