import axios from "axios";

import catchErrors from "../helpers/catchErrors";

import { Day, MenuModel, ScheduleModel } from "@/@types/model";

import { API_URL } from "../config";

const getByMenuId = catchErrors<MenuModel | null>(async (menuId: string | number) => {
  const { data, status } = await axios.get<MenuModel>(`${API_URL}/menus/${menuId}/schedule`);
  return status === 200 && data ? data : null;
});

const save = catchErrors<ScheduleModel | null>(
  async (schedule: ScheduleModel) => {
    const { data, status } = await axios.post<ScheduleModel>(`${API_URL}/menus/schedule`, schedule);
    return status === 200 && data ? data : null;
  },
  { isThrow: true },
);

/* Common Methods */

// If status equal 'SHOW_ALL' or 'HIDE_ALL', then delete all timeWindows
const deleteUnnecessaryTimeWindows = (day: Day) => ({
  ...day,
  timeWindows: day.status && ["SHOW_ALL", "HIDE_ALL"].includes(day.status) ? [] : day.timeWindows,
});

// If timeWindows is empty, then replace the status with "SHOW_ALL"
const replaceStatusIfTimeWindowsEmpty = (day: Day) => ({
  ...day,
  status: day.status === "SPECIFIC" && !day.timeWindows.length ? "SHOW_ALL" : day.status,
});

// If timeWindow equal [00:00 - 00:00], then replace the status with "SHOW_ALL"
const replaceStatusIfTimeWindowsFull = (day: Day) => ({
  ...day,
  status:
    day.status === "SPECIFIC" &&
    day.timeWindows.some((timeWindow) => timeWindow.start === "00:00" && timeWindow.end === "00:00")
      ? "SHOW_ALL"
      : day.status,
});

export default {
  getByMenuId,
  save,

  deleteUnnecessaryTimeWindows,
  replaceStatusIfTimeWindowsEmpty,
  replaceStatusIfTimeWindowsFull,
};
