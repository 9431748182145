










































































































import { Component, Vue } from "vue-property-decorator";
import { Get, Call } from "vuex-pathify";

import VPhotoUploader from "../../photos/VPhotoUploader.vue";

import { MenuModel, MenuSettingsModel, AppSettingsModel } from "@/@types/model";

@Component({
  name: "VMenuSettingsEditorCard",
  components: { VPhotoUploader },
})
export default class VMenuSettingsEditorCard extends Vue {
  @Get("menu/menu") readonly menu!: MenuModel;
  @Get("menu/menuSettings") readonly menuSettings!: MenuSettingsModel;
  @Get("app/settings") readonly appSettings!: AppSettingsModel;
  @Get("menu/isMenuBusy") readonly isMenuBusy!: boolean;

  @Call("menu/updateMenu") updateMenu!: (payload: Partial<MenuModel>) => Promise<void>;
  @Call("menu/saveMenu") saveMenu!: (payload: Partial<MenuModel>) => Promise<void>;
  @Call("menu/discardMenu") discardMenu!: (payload: Array<keyof MenuModel>) => Promise<void>;

  get settings() {
    return this.menuSettings || this.appSettings;
  }

  handleChangeTitle(value: string) {
    const title = { ...this.menu.title, [this.settings.defaultLanguage]: value };
    this.updateMenu({ title });
  }

  handleChangeInternalComment(value: string) {
    const internalComment = {
      ...this.menu.internalComment,
      [this.settings.defaultLanguage]: value,
    };
    this.updateMenu({ internalComment });
  }

  handleChangeStatusMenu(isPublished: boolean) {
    this.updateMenu({
      isPublished,
      isEnabledToOrder: isPublished ? this.menu.isEnabledToOrder : false,
    });
  }

  handleChangeEnabledOrderMenu(isEnabledToOrder: boolean) {
    this.updateMenu({ isEnabledToOrder });
  }

  handleChangePriceVisible(isPriceVisible: boolean) {
    this.updateMenu({ isPriceVisible });
  }

  handleChangeEnergyVisible(isEnergyVisible: boolean) {
    this.updateMenu({ isEnergyVisible });
  }

  handleDiscardChanges() {
    this.discardMenu([
      "photo",
      "title",
      "internalComment",
      "isPublished",
      "isEnabledToOrder",
      "isPriceVisible",
      "isEnergyVisible",
    ]);

    this.$emit("discard");
  }

  handleCancelChanges() {
    this.handleDiscardChanges();

    this.$emit("cancel");
  }

  async handleSaveChanges() {
    const fields: Partial<MenuModel> = {
      photo: this.menu.photo,
      title: this.menu.title,
      internalComment: this.menu.internalComment,
      isPublished: this.menu.isPublished,
      isEnabledToOrder: this.menu.isEnabledToOrder,
      isPriceVisible: this.menu.isPriceVisible,
      isEnergyVisible: this.menu.isEnergyVisible,
    };
    await this.saveMenu(fields);

    this.$emit("save", fields);
  }
}
