<template>
  <DialogCard :actions="actions" :action-options="{ flat: false }">
    <template #title>
      <v-toolbar color="warning" dark dense flat>
        <v-icon left>mdi-exclamation</v-icon>
        <v-toolbar-title> {{ $i18n.t("Auth.VerifyYourPassword") }} </v-toolbar-title>
        <v-divider></v-divider>
        <v-icon @click="$emit('submit', false)">mdi-close</v-icon>
      </v-toolbar>
    </template>
    <template #default>
      <p v-if="description">{{ description }}</p>
      <form action="">
        <v-text-field
          v-model="password"
          :label="$i18n.t('Auth.Password')"
          type="password"
          icon="lock"
          autofocus
        />
      </form>
    </template>
  </DialogCard>
</template>

<script>
import axios from "axios";

import { API_URL } from "../config";

export default {
  name: "VVerifyPasswordCard",

  props: ["description"],

  data() {
    return {
      password: "",
    };
  },

  computed: {
    actions() {
      return {
        false: { text: this.$i18n.t("GlobalUI.Cancel") },
        true: {
          color: "error",
          text: this.$i18n.t("GlobalUI.Delete"),
          handle: () => this.verify(),
        },
      };
    },
  },

  methods: {
    async verify() {
      try {
        await axios.post(`${API_URL}/user/check`, { password: this.password });
        return true;
      } catch (e) {
        return false;
      }
    },
  },
};
</script>