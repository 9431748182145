import axios from "axios";

import { getQueryParams, QueryParams } from "@/helpers/queryParams";
import catchErrors from "@/helpers/catchErrors";

import { TranslateField } from "@/@types/common";
import { API_URL } from "../config";

const getIngredients = catchErrors<TranslateField[]>(
  async ({ filters }: { filters: QueryParams }) => {
    const queryParams = getQueryParams(filters);

    const { data, status } = await axios.get(`${API_URL}/ingredients/default${queryParams}`);

    return status === 200 && data ? data : [];
  },
);

export default { getIngredients };
