<template>
  <div
    class="dish-item"
    :class="[
      dish.isPublished ? 'published' : 'unpublished',
      dish.isEnabledToOrder ? 'enabled-order' : 'unenabled-order',
    ]"
  >
    <div class="dish-item__move">
      <v-btn class="move" :disabled="isDraggableDisabled" icon>
        <v-icon>mdi-unfold-more-horizontal</v-icon>
      </v-btn>
    </div>
    <div class="dish-item__photo">
      <v-img-extend
        class="rounded-lg"
        :width="120"
        :height="120"
        :img="dish.photo"
        fallback-size="sm"
        size="sm"
      />
    </div>
    <div class="dish-item__content">
      <v-dish-availability-block
        :is-published="dish.isPublished"
        :is-enabled-to-order="dish.isEnabledToOrder"
        @change:published="handleChangePublishedDish"
        @change:enabled-order="handleChangeEnabledOrderDish"
      />

      <div class="dish-item__title">
        <span v-if="dish.internalId" class="dish-item__internalId"> #{{ dish.internalId }} </span>
        {{ dish.title[menuSettings.defaultLanguage] }}
      </div>
      <div class="dish-item__description">
        <v-truncate
          clamp="..."
          less=""
          :length="120"
          :text="dish.description[menuSettings.defaultLanguage]"
        />
      </div>
      <div class="dish-item__meta">
        <span v-if="dish.options.length > 1">
          {{ dish.options.length }} {{ $t("Menu.Options").toLowerCase() }}
        </span>
        <span v-if="dish.extras.length">
          {{ dish.extras.length }} {{ $t("Menu.Extras").toLowerCase() }}
        </span>
      </div>
    </div>
    <div class="dish-item__actions">
      <v-btn @click="handleDuplicateDish" icon>
        <v-icon>mdi-content-duplicate</v-icon>
      </v-btn>
      <v-btn @click="handleEditDish" icon>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn @click="handleDeleteDish" icon>
        <v-icon color="error">mdi-close-circle-outline</v-icon>
      </v-btn>
    </div>
    <div class="dish-item__price">
      <span v-if="dish.options.length > 1 && dish.optionsType !== 'SAME'">
        {{ $t("GlobalUI.From").toLowerCase() }}
      </span>
      {{ minPrice }}
      {{ currency }}
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import { mapActions, mapGetters } from "vuex";

import VDishAvailabilityBlock from "../VDishAvailabilityBlock.vue";
import VTruncate from "../../VTruncate";

export default {
  name: "VDishItem",

  props: ["dish", "currency", "isDraggableDisabled"],

  components: { VDishAvailabilityBlock, VTruncate },

  computed: {
    ...mapGetters({
      menuSettings: "menu/menuSettings",
    }),
    minPrice() {
      const prices = this.dish.options
        .map((option) => option.priceValue)
        .filter((price) => !!price);

      return prices.length ? Math.min(...prices) : 0;
    },
  },

  methods: {
    ...mapActions({
      updateDish: "menu/updateDish",
    }),

    handleChangePublishedDish({ isPublished, isEnabledToOrder }) {
      this.updateDish({
        categoryId: this.dish.categoryId,
        dishId: this.dish.id,
        dish: { isPublished, isEnabledToOrder },
      });
    },

    handleChangeEnabledOrderDish({ isEnabledToOrder }) {
      this.updateDish({
        categoryId: this.dish.categoryId,
        dishId: this.dish.id,
        dish: { isEnabledToOrder },
      });
    },

    handleDuplicateDish() {
      const language = this.menuSettings.defaultLanguage;
      this.$emit("dish:duplicate", {
        ...this.dish,
        id: uuid(),
        title: {
          ...this.dish.title,
          [language]: "Copy of " + this.dish.title[language],
        },
      });
    },

    handleEditDish() {
      this.$emit("dish:edit", this.dish);
    },

    handleDeleteDish() {
      this.$emit("dish:delete", this.dish);
    },
  },
};
</script>

<style lang="scss" scoped>
.move {
  cursor: move;
}

.ghost {
  opacity: 0.3;
  background: #eee;
  color: grey !important;
}

.dish-item {
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-template-areas:
    "move photo content actions"
    "move photo content price";
  border-bottom: 1px solid #ddd;
  border-left: 5px solid transparent;
  padding: 15px 16px;
  padding-left: 0;
  &.published {
    border-left-color: #4caf50;
  }
  &.unpublished {
    border-left-color: #ff9800;
  }
  &:last-of-type {
    border-bottom: none;
  }
  &__move {
    grid-area: move;
    align-self: center;
    margin: 0 5px;
  }
  &__photo {
    grid-area: photo;
    margin-right: 12px;
  }
  &__content {
    grid-area: content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__title {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.4;
  }
  &__internalId {
    &:after {
      content: "•";
    }
  }
  &__description,
  &__meta {
    font-size: 0.875rem;
    // color: rgba(0, 0, 0, 0.6);
  }
  &__meta {
    span {
      position: relative;
      &:after {
        content: "•";
      }
      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
  }
  &__description {
    flex-grow: 1;
    margin-bottom: 10px;
  }
  &__actions {
    grid-area: actions;
    display: flex;
    justify-content: flex-end;
  }
  &__price {
    grid-area: price;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 1rem;
    span {
      margin-right: 5px;
    }
  }
}

@media screen and (max-width: 576px) {
  .dish-item {
    display: grid;
    grid-template-columns: 60px auto auto;
    grid-template-areas:
      "move photo actions"
      "move content content"
      "move . price";
    &__move {
      display: flex;
      justify-content: center;
    }
    &__photo {
      margin-bottom: 20px;
    }
  }
}

.v-application.theme--dark {
  .dish-item {
    border-bottom-color: #555;
  }
}
</style>