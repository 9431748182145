import axios from "axios";

import catchErrors from "@/helpers/catchErrors";

import { AllergenModel } from "@/@types/model";
import { API_URL } from "../config";

const getAllergens = catchErrors<AllergenModel[]>(
  async () => {
    const { data, status } = await axios.get(`${API_URL}/allergens`);

    return status === 200 && data ? data : [];
  },
  { isThrow: true },
);

export default { getAllergens };
