<template>
  <v-toolbar class="menu-toolbar rounded" elevation="2">
    <v-toolbar-title class="menu-toolbar__title">
      <template v-if="isCreateMenu">{{ $t("Menu.CreateMenu") }}</template>
      <template v-else-if="!isMenuLoading">
        {{ $t("Menu.EditMenu") }}: {{ menu.title[defaultLanguage] }}
      </template>
      <template v-else>{{ $t("GlobalUI.Loading") }} ...</template>
    </v-toolbar-title>

    <div class="d-flex justify-space-between align-center flex-grow-1" v-if="!isTablet">
      <div class="d-flex align-center" v-if="!isTablet && !isMenuLoading && !isMenuEqualCache">
        <v-btn
          @click="handleDiscardMenu"
          class="ml-3"
          :loading="isMenuBusy"
          :disabled="isMenuBusy"
          small
        >
          {{ $t("GlobalUI.Discard") }}
        </v-btn>
        <v-btn
          @click="handleSaveMenu"
          class="ml-3"
          color="warning"
          :loading="isMenuBusy"
          :disabled="isMenuBusy"
          small
        >
          {{ $t("GlobalUI.Save") }}
        </v-btn>
      </div>
      <v-spacer v-else />

      <div class="d-flex align-center">
        <v-btn
          class="mr-3"
          @click="handleClickAction('preview')"
          :disabled="!menu.id || isMenuBusy"
          small
        >
          <v-icon v-if="isTablet"> mdi-cellphone </v-icon>
          {{ !isTablet ? $t("GlobalUI.Preview") : "" }}
        </v-btn>

        <v-btn
          @click="$router.push({ name: 'menus.localizer', params: { menuId: menu.id } })"
          :disabled="!menu.id || isMenuBusy"
          icon
        >
          <v-icon> mdi-translate </v-icon>
        </v-btn>

        <v-btn @click="handleClickAction('schedule')" :disabled="!menu.id || isMenuBusy" icon>
          <v-icon> mdi-calendar-clock </v-icon>
        </v-btn>

        <v-btn @click="handleClickAction('settings')" icon>
          <v-icon> mdi-cog </v-icon>
        </v-btn>
      </div>
    </div>
  </v-toolbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "VMenuToolbar",

  computed: {
    ...mapGetters({
      defaultLanguage: "app/defaultLanguage",
      menu: "menu/menu",
      isMenuEqualCache: "menu/isMenuEqualCache",
      isMenuLoading: "menu/isMenuLoading",
      isMenuBusy: "menu/isMenuBusy",
      categoryById: "menu/categoryById",
      selectedCategoryId: "menu/selectedCategoryId",
    }),
    isCreateMenu() {
      return (
        this.$router.history.current.name === "menus.create" ||
        this.$router.history.current.name === "menus.create.first"
      );
    },
  },

  methods: {
    ...mapActions({
      saveMenu: "menu/saveMenu",
      discardMenu: "menu/discardMenu",
    }),

    async handleSaveMenu() {
      await this.saveMenu();
    },

    handleDiscardMenu() {
      this.discardMenu();
    },

    handleClickAction(type) {
      this.$emit("action", type);
    },
  },
};
</script>