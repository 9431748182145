<template>
  <div class="menu-create">
    <v-menu-management />
  </div>
</template>

<script>
import confirmBeforeLeaveMenu from "../../../mixins/confirmBeforeLeaveMenu";

import VMenuManagement from "../../../components/menus/VMenuManagement";

export default {
  name: "MenuCreate",

  middleware: "auth",

  mixins: [confirmBeforeLeaveMenu],

  metaInfo() {
    return { title: "Menu Create" };
  },

  components: {
    VMenuManagement,
  },

  mounted() {
    this.$store.dispatch("menu/fetchDefaultMenu");
  },
};
</script>

<style lang="scss" scoped>
.menu-create {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 125px);
}
</style>
