<template>
  <div class="categories-editor">
    <v-card elevation="2">
      <v-toolbar flat>
        <v-toolbar-title> {{ $t("Menu.Categories") }} </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-add-btn :action="() => (isShowCategoryEditor = true)" size="42px" />
      </v-toolbar>

      <v-divider></v-divider>

      <div
        class="categories-editor__content"
        :class="{
          'categories-editor__content--loading': isMenuLoading,
          'categories-editor__content--empty': !isMenuLoading && !categories.length,
        }"
      >
        <v-vertical-scrolling v-if="!isMenuLoading && categories.length">
          <div class="categories-list">
            <draggable
              v-model="categories"
              handle=".move"
              group="categories"
              v-bind="dragOptions"
              @start="dragCategory = true"
              @end="dragCategory = false"
            >
              <transition-group
                type="transition"
                tag="div"
                :name="!dragCategory ? 'flip-list' : null"
              >
                <v-category-item
                  v-for="category in categories"
                  :key="category.id"
                  :category="category"
                  @category:select="handleSelectCategory"
                  @category:toggle-visibility="handleToggleVisibilityCategory"
                  @category:edit="handleEditCategory"
                  @category:delete="handleDeleteCategory"
                />
              </transition-group>
            </draggable>
          </div>
        </v-vertical-scrolling>
        <v-progress-circular
          v-if="isMenuLoading"
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <div v-if="!isMenuLoading && !categories.length">{{ $t("Messages.Empty") }}</div>
      </div>
    </v-card>

    <v-dialog
      :value="isShowCategoryEditor"
      max-width="768px"
      overlay-color="#2e2e2e"
      overlay-opacity="0.8"
      persistent
    >
      <v-category-editor-card
        :edit-category="editCategory"
        :is-open="isShowCategoryEditor"
        @category:cancel="handleCancelCategory"
        @category:save="handleSaveCategory"
        @default-category:show="handleShowDefaultCategories"
      />
    </v-dialog>

    <v-dialog
      :value="isShowDefaultCategories"
      max-width="768px"
      overlay-color="#2e2e2e"
      overlay-opacity="0.8"
      scrollable
      persistent
    >
      <v-category-default-card @click:close="isShowDefaultCategories = false" />
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";

import VVerticalScrolling from "../../VVerticalScrolling";
import VCategoryItem from "../items/VCategoryItem";
import VCategoryEditorCard from "./VCategoryEditorCard";
import VCategoryDefaultCard from "./VCategoryDefaultCard";
import VVerifyPasswordCard from "../../VVerifyPasswordCard";

export default {
  name: "VCategoriesEditorCard",

  components: {
    draggable,
    VVerticalScrolling,
    VCategoryItem,
    VCategoryEditorCard,
    VCategoryDefaultCard,
  },

  data() {
    return {
      dragCategory: false,

      isShowCategoryEditor: false,
      editCategory: null,

      isShowDefaultCategories: false,
    };
  },

  computed: {
    ...mapGetters({
      isMenuLoading: "menu/isMenuLoading",
      isMenuBusy: "menu/isMenuBusy",
    }),
    categories: {
      get() {
        return this.$store.state.menu.menu.categories;
      },
      set(value) {
        this.setCategories(value);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  methods: {
    ...mapActions({
      setCategories: "menu/setCategories",
      setSelectedCategoryId: "menu/setSelectedCategoryId",
      updateCategory: "menu/updateCategory",
      deleteCategory: "menu/deleteCategory",
    }),

    handleSelectCategory({ id }) {
      this.setSelectedCategoryId(id);
    },

    handleToggleVisibilityCategory({ id: categoryId, visibility }) {
      this.updateCategory({
        categoryId,
        data: { visibility: !visibility },
      });
    },

    handleShowDefaultCategories() {
      this.isShowCategoryEditor = false;
      this.editCategory = null;

      this.isShowDefaultCategories = true;
    },

    handleCancelCategory() {
      this.isShowCategoryEditor = false;
      setTimeout(() => (this.editCategory = null), 500);
    },

    handleSaveCategory() {
      this.isShowCategoryEditor = false;
      setTimeout(() => (this.editCategory = null), 500);
    },

    handleEditCategory(category) {
      this.isShowCategoryEditor = true;

      if (category) {
        this.$nextTick(function () {
          this.editCategory = category;
        });
      }
    },

    async handleDeleteCategory({ id, dishes }) {
      if (!dishes.length) {
        this.deleteCategory(id);
        return;
      }

      const isValid = await this.$dialog.showAndWait(VVerifyPasswordCard, { showClose: false });
      if (!isValid) return;
      this.deleteCategory(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.move {
  cursor: move;
}

.ghost {
  opacity: 0.3;
  background: #eee;
  color: grey !important;
}

.categories-editor {
  &__content {
    width: 100%;
    height: calc(100vh - 240px);
    &--loading,
    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 991px) {
  .categories-editor {
    &__content {
      height: auto;
      min-height: 120px;
    }
  }
}
</style>
