<template>
  <div class="dish-availability-block">
    <div class="dish-published" :class="localIsPublished ? 'published' : 'unpublished'">
      <v-switch
        class="dish-published__switch"
        v-model="localIsPublished"
        @change="handleChangeStatusDish"
        hide-details
        inset
        dense
      ></v-switch>
      <span class="dish-published__label">
        {{ localIsPublished ? $t("Menu.Published") : $t("Menu.Unpublished") }}
      </span>
    </div>
    <div
      class="dish-enabled-order"
      :class="localIsEnabledToOrder ? 'enabled-order' : 'unenabled-order'"
    >
      <v-switch
        class="dish-enabled-order__switch"
        v-model="localIsEnabledToOrder"
        @change="handleChangeEnabledOrderDish"
        :disabled="!localIsPublished"
        hide-details
        inset
        dense
      ></v-switch>
      <span class="dish-published__label">
        {{ localIsEnabledToOrder ? $t("Menu.EnabledToOrder") : $t("Menu.DisabledToOrder") }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "VDishAvailabilityBlock",

  props: ["isPublished", "isEnabledToOrder"],

  data() {
    return {
      localIsPublished: this.isPublished,
      localIsEnabledToOrder: this.isEnabledToOrder,
    };
  },

  watch: {
    isPublished(value) {
      this.localIsPublished = value;
    },
    isEnabledToOrder(value) {
      this.localIsEnabledToOrder = value;
    },
  },

  methods: {
    handleChangeStatusDish(isPublished) {
      this.$emit("change:published", {
        isPublished,
        isEnabledToOrder: isPublished ? this.localIsEnabledToOrder : false,
      });
    },

    handleChangeEnabledOrderDish(isEnabledToOrder) {
      this.$emit("change:enabled-order", { isPublished: this.localIsPublished, isEnabledToOrder });
    },
  },
};
</script>

<style lang="scss" scoped>
.dish-availability-block {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  .dish-published,
  .dish-enabled-order {
    display: flex;
    align-items: center;
    &.published,
    &.enabled-order {
      color: #4caf50;
    }
    &.unpublished,
    &.unenabled-order {
      color: #ff9800;
    }
    &__switch {
      margin-top: 0;
      padding-top: 0;
    }
    &__label {
      font-size: 0.8rem;
      line-height: 1.375rem;
      letter-spacing: 1px;
      font-weight: 500;
      margin-left: -5px;
      text-transform: uppercase;
      opacity: 0.8;
    }
  }
}

@media screen and (max-width: 768px) {
  .dish-availability-block {
    grid-template-columns: 100%;
    gap: 10px;
    margin-left: 2px;
  }
}
</style>