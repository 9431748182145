<template>
  <div class="dish-extras">
    <div class="dish-extra" v-for="(extra, idx) in localExtras" :key="extra.id">
      <div class="dish-extra__header">
        <h4>{{ $t("Menu.Extra") }} {{ idx + 1 }}</h4>
        <div class="dish-extra__actions">
          <v-btn
            class="ml-3"
            v-if="extra.length !== 1"
            @click="handleDeleteExtra(extra.id)"
            color="error"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="dish-extra__content">
        <v-text-field
          v-model.trim="extra.title[menuSettings.defaultLanguage]"
          class="dish-extra__control dish-extra__control--amount"
          :label="`${$t('GlobalUI.Title')} *`"
          @blur="$v.dish.extras.$each[idx].title[menuSettings.defaultLanguage].$touch()"
          :error-messages="titleErrors(idx)"
          dense
        />
        <v-text-field
          v-model.trim="extra.energy"
          class="dish-extra__control dish-extra__control--energy"
          :class="{ 'dish-extra__control--energy--off': !menu.isEnergyVisible }"
          :label="$t('Menu.Energy')"
          :suffix="`${$t('Menu.Cal')}.`"
          @blur="$v.dish.extras.$each[idx].energy.$touch()"
          :error-messages="numericErrors(idx, 'energy')"
          dense
        />
        <v-text-field
          v-model="extra.priceValue"
          :suffix="appSettings.defaultCurrency"
          :label="$t('Menu.Price')"
          @blur="$v.dish.extras.$each[idx].priceValue.$touch()"
          :error-messages="numericErrors(idx, 'priceValue')"
          dense
        />
      </div>
    </div>
    <a
      class="dish-extra__add"
      v-if="localExtras.length < 30"
      href="javascript:void(0)"
      @click.prevent="handleAddExtra"
      color="primary"
      small
      link
    >
      + {{ $t("Menu.AddExtra") }}
    </a>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import { mapGetters } from "vuex";

import menusService from "../../services/menus.service";

export const extraSchema = (languages) => ({
  id: uuid(),
  title: menusService.generateLanguageObject(languages, "string"),
  energy: "",
  priceValue: null,
});

export default {
  name: "VDishExtrasEditor",

  props: ["extras"],

  inject: ["$v"],

  data() {
    return {
      localExtras: this.extras,
    };
  },

  watch: {
    extras(val) {
      this.localExtras = val;
    },
    localExtras: {
      handler(val, oldVal) {
        if (!oldVal) return;
        this.$emit("change", val);
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      menu: "menu/menu",
      menuSettings: "menu/menuSettings",
      appSettings: "app/settings",
    }),

    /* Validations */
    titleErrors() {
      return (idx) => {
        const errors = [];
        const defaultLanguage = this.menuSettings.defaultLanguage;
        if (!this.$v.dish.extras.$each[idx].title[defaultLanguage].$dirty) return errors;
        !this.$v.dish.extras.$each[idx].title[defaultLanguage].required &&
          errors.push(this.$t("Validation.FieldRequired"));
        return errors;
      };
    },

    numericErrors() {
      return (idx, field) => {
        const errors = [];
        if (!this.$v.dish.extras.$each[idx][field].$dirty) return errors;
        !this.$v.dish.extras.$each[idx][field].decimal &&
          errors.push("The field must be a numeric.");
        !this.$v.dish.extras.$each[idx][field].minValue &&
          errors.push("The field must be at least 0.");
        return errors;
      };
    },
  },

  methods: {
    handleAddExtra() {
      const { defaultLanguage, alternativeLanguages } = this.menuSettings;
      const languages = [defaultLanguage, ...alternativeLanguages];

      this.localExtras.push(extraSchema(languages));
    },

    handleDeleteExtra(extraId) {
      this.localExtras = this.localExtras.filter((extra) => extra.id !== extraId);
    },
  },
};
</script>

<style lang="scss" scoped>
.dish-extras {
  margin-top: 16px;
  border: 2px solid #e9f8f7;
  padding: 15px 0;
}

.dish-extra {
  &:first-of-type {
    .dish-extra__header {
      margin-top: -15px;
    }
  }
  &__header {
    min-height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 16px;
    margin-bottom: 16px;
    background-color: $secondary-color;
    border-color: $secondary-color;
  }
  &__content {
    padding: 5px 16px;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 5px 20px;
  }
  &__control {
    &--energy {
      &--off {
        opacity: 0.3;
      }
    }
  }
  &__add {
    display: inline-block;
    padding: 0 15px;
    text-decoration: none;
  }
}

.v-application.theme--dark {
  .dish-extras {
    border-color: #333;
  }
  .dish-extra {
    &__header {
      background-color: darken($secondary-color, 70%);
      border-color: darken($secondary-color, 70%);
    }
  }
}
</style>