<template>
  <div class="dish-options">
    <v-radio-group
      v-if="localOptions.length !== 1"
      class="dish-options-type"
      :value="localOptionsType"
      @change="handleOptionsTypeChange"
      hide-details
      row
    >
      <v-radio :label="$t('Menu.Variations')" value="SAME"></v-radio>
      <v-radio :label="$t('Menu.Options')" value="DIFFERENT"></v-radio>
    </v-radio-group>

    <div
      class="dish-options-list"
      :class="{
        'dish-options-list--same': localOptionsType === 'SAME',
        'dish-options-list--different': localOptionsType === 'DIFFERENT',
      }"
    >
      <div class="dish-option" v-for="(option, idx) in localOptions" :key="option.id">
        <div
          v-if="options.length !== 1"
          class="dish-option__header"
          :class="{
            'dish-option__header--error': $v.dish.options.$each[idx].title.$error,
          }"
        >
          <h4>{{ $t("Menu.Option") }} {{ idx + 1 }}:</h4>
          <v-text-field
            class="dish-option__control dish-option__control--title"
            v-model="option.title[menuSettings.defaultLanguage]"
            :placeholder="`${$t('GlobalUI.Title')} *`"
            @blur="$v.dish.options.$each[idx].title[menuSettings.defaultLanguage].$touch()"
            :error-messages="titleErrors(idx)"
            hide-details="auto"
            dense
          />
          <div class="dish-option__actions">
            <v-switch
              :input-value="defaultOption"
              :true-value="option.id"
              :false-value="null"
              @change="handleChangeDefaultOption"
            />
            <v-btn
              v-if="options.length !== 1"
              @click="handleDeleteOption(option.id)"
              color="error"
              icon
            >
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </div>
        </div>
        <template v-if="localOptionsType === 'DIFFERENT' && localOptions.length !== 1">
          <div class="dish-option__content">
            <v-text-field
              v-model.trim="option.amount[menuSettings.defaultLanguage]"
              class="dish-option__control dish-option__control--amount"
              :label="$t('Menu.Amount')"
              dense
            />
            <div class="dish-option__energy-and-price">
              <v-text-field
                class="dish-option__control dish-option__control--energy"
                :class="{ 'dish-option__control--energy--off': !menu.isEnergyVisible }"
                v-model.trim="option.energy"
                :label="$t('Menu.Energy')"
                :suffix="`${$t('Menu.Cal')}.`"
                @blur="$v.dish.options.$each[idx].energy.$touch()"
                :error-messages="numericErrors(idx, 'energy')"
                dense
              />
              <v-text-field
                class="dish-option__control dish-option__control--price"
                v-model="option.priceValue"
                :label="$t('Menu.Price')"
                :suffix="appSettings.defaultCurrency"
                @blur="$v.dish.options.$each[idx].priceValue.$touch()"
                :error-messages="numericErrors(idx, 'priceValue')"
                dense
              />
            </div>
          </div>
        </template>
      </div>
    </div>
    <a
      class="dish-option__add"
      v-if="options.length < 20"
      href="javascript:void(0)"
      @click.prevent="handleAddOption"
      color="primary"
      small
      link
    >
      + {{ localOptionsType === "SAME" ? $t("Menu.AddVariation") : $t("Menu.AddOption") }}
    </a>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import { mapGetters } from "vuex";

import menusService from "../../services/menus.service";

export const optionSchema = (languages) => ({
  id: uuid(),
  title: menusService.generateLanguageObject(languages, "string"),
  amount: menusService.generateLanguageObject(languages, "string"),
  energy: "",
  priceValue: null,
});

export default {
  name: "VDishOptionsEditor",

  props: ["options", "defaultOption", "type"],

  inject: ["$v"],

  data() {
    return {
      localOptionsType: this.type,
      localOptions: this.options,
    };
  },

  watch: {
    type(optionsType) {
      this.localOptionsType = optionsType;
    },
    options(options) {
      this.localOptions = options;
    },
    localOptions: {
      handler(options, oldOptions) {
        if (!oldOptions.length) return;
        this.$emit("change:options", options);
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      menu: "menu/menu",
      menuSettings: "menu/menuSettings",
      appSettings: "app/settings",
    }),

    /* Validations */
    titleErrors() {
      return (idx) => {
        const errors = [];
        const defaultLanguage = this.menuSettings.defaultLanguage;
        if (!this.$v.dish.options.$each[idx].title[defaultLanguage].$dirty) return errors;
        !this.$v.dish.options.$each[idx].title[defaultLanguage].required &&
          errors.push(this.$t("Validation.FieldRequired"));
        return errors;
      };
    },

    numericErrors() {
      return (idx, field) => {
        const errors = [];
        if (!this.$v.dish.options.$each[idx][field].$dirty) return errors;
        !this.$v.dish.options.$each[idx][field].decimal &&
          errors.push("The field must be a numeric.");
        !this.$v.dish.options.$each[idx][field].minValue &&
          errors.push("The field must be at least 0.");
        return errors;
      };
    },
  },

  methods: {
    handleOptionsTypeChange(optionsType) {
      this.localOptionsType = optionsType;
      this.$emit("change:type", optionsType);
    },

    handleAddOption() {
      const { defaultLanguage, alternativeLanguages } = this.menuSettings;
      const languages = [defaultLanguage, ...alternativeLanguages];

      this.localOptions.push(optionSchema(languages));
    },

    handleDeleteOption(optionId) {
      this.localOptions = this.localOptions.filter((option) => option.id !== optionId);
    },

    handleChangeDefaultOption(value) {
      this.$emit("change:default-option", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.dish-options {
  border: 2px solid #e9f8f7;
  padding: 15px 0;
}

.dish-options-type {
  padding: 0 15px;
  margin: 0;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.dish-options-list {
  &--different {
    .dish-option__content {
      grid-template-columns: 1fr 1fr;
    }
    .dish-option__energy-and-price {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.dish-option {
  &__header {
    min-height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 16px;
    margin-bottom: 12px;
    background-color: $secondary-color;
    border-color: $secondary-color;
    &--error {
      min-height: 60px;
    }
  }
  &__content {
    padding: 5px 16px;
    display: grid;
    gap: 5px 20px;
  }
  &__energy-and-price {
    display: grid;
    gap: 5px 20px;
  }
  &__control {
    margin: 0;
    &--title {
      margin-left: 10px;
      margin-top: -7px;
    }
    &--energy {
      &--off {
        opacity: 0.3;
      }
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
  &__add {
    display: inline-block;
    padding: 0 15px;
    text-decoration: none;
  }
}

.v-application.theme--dark {
  .dish-options {
    border-color: #333;
  }
  .dish-option {
    &__header {
      background-color: darken($secondary-color, 70%);
      border-color: darken($secondary-color, 70%);
    }
  }
}
</style>